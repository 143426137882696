"use client";
import React from "react";

import {
    Backers,
    Footer,
    Introduction,
    Roadmap,
    Socials,
    Tokenomics,
    Web3TokenInfo,
    WhoUs
} from "../../components/Sections"
import News from "../../components/Sections/news";


export default function Home() {

    return (
        <div className="flex flex-col space-y-16">
            <video
                autoPlay
                muted
                loop
                className="rotate-180 absolute top-[-480px] md:-top-[600px] lg:top-[-480px]  h-full w-full left-0 object-cover "
            >
                <source src="/blackhole.webm" type="video/webm"/>
            </video>

            <Introduction/>
            <div className="pt-20 flex flex-col space-y-16">
                <Backers/>
                <Socials/>
                <WhoUs/>
                <Tokenomics/>
                <Web3TokenInfo/>
                <Roadmap/>
                <News/>
            </div>
            <Footer/>
        </div>
    );
}