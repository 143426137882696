import { ReactNode } from 'react'
import { Transition } from 'react-transition-group'
import useMediaQuery from '../../hooks/useMediaQuery.jsx'

import Modal, { Styles } from 'react-modal'
import { GrFormClose } from 'react-icons/gr'



Modal.setAppElement('#root')

const MainModal = ({ isOpen, onClose, children }) => {
  const matches = useMediaQuery('(min-width: 768px)')

  const mobileContent = {
    bottom: 0,
    left: 0,
    right: 0,
    padding: 0,
  }

  const customStyles: Styles = {
    overlay: {
      background: 'rgba(0, 0, 0, 0.2)',
      opacity: 0,
      transition: 'opacity 0.3s ease-in-out',
      backdropFilter: 'blur(3px)'
    },
    content: {
      border: 'none',
      background: 'transparent',
      display: 'flex',
      justifyContent: 'center',
      alignItems: matches ? 'center' : 'end',
      pointerEvents: 'none',
      opacity: 0,
      zIndex: 11,
      transition: 'opacity 0.3s ease-in-out',
      ...(!matches ? mobileContent : {}),
    },
  }

  return (
    <Transition in={isOpen} timeout={300} mountOnEnter unmountOnExit>
      {state => {
        return (
          <Modal
            style={{
              content: {
                ...customStyles.content,
                opacity: state === 'entered' ? 1 : 0,
              },
              overlay: {
                ...customStyles.overlay,
                opacity: state === 'entered' ? 1 : 0,
              },
            }}
            isOpen
            onRequestClose={onClose}
          >
            <div
              className={`w-full md:w-[592px] border border-white rounded-xl   bg-white/10 backdrop-blur-xl px-6 py-12 md:p-16 text-center pointer-events-auto transition-transform duration-300 relative ${
                state === 'entered'
                  ? 'translate-y-0 md:scale-100'
                  : 'translate-y-full md:scale-0'
              }`}
            >
              <button
                onClick={onClose}
                className="absolute top-5 right-5 w-4 h-4 flex justify-center items-center text-3xl"
              >
                <span className="opacity-60">
                  <GrFormClose color='white' />
                </span>
              </button>
              {children}
            </div>
          </Modal>
        )
      }}
    </Transition>
  )
}

export default MainModal
