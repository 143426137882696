import {useEffect, useRef} from 'react';
import {gsap} from 'gsap';
import { toast } from 'react-hot-toast'

export default function Introduction() {
    const headingRef = useRef(null);
    const subheadingRef = useRef(null);
    const buttonRef = useRef(null);
    const dAppsRef = useRef(null);

    useEffect(() => {
        const tl = gsap.timeline({paused: true});

        // Animate the heading
        tl.fromTo(
            headingRef.current,
            {color: 'transparent'},
            {color: '#00EAFF', duration: 1, ease: "power2.inOut"}
        )
            .fromTo(
                subheadingRef.current,
                {color: 'transparent'},
                {color: '#D1D5DB', duration: 1, ease: "power2.inOut"},
                "-=0.5" // Overlap the start with the heading animation
            )
            .fromTo(
                buttonRef.current,
                {opacity: 0, scale: 0.5},
                {opacity: 1, scale: 1, duration: 0.5, ease: "bounce.out"},
                "-=0.5" // Overlap the start with the subheading animation
            )
            .fromTo(
                dAppsRef.current,
                {opacity: 0, scale: 0.5},
                {opacity: 1, scale: 1, duration: 0.5, ease: "bounce.inOut"},
            )

        tl.play();
    }, []);

    return (
        <div
            className="flex flex-col text-center pt-12 lg:pt-20 lg:h-[70vh]  items-center space-y-16 relative overflow-hidden"
        >
            {/* Background Animation */}
            <div
                className="absolute inset-0 bg-cover bg-center opacity-70"
                style={{backgroundImage: "url('/images/introBackground.png')"}}
            ></div>

            <div className="h-fit space-y-4 lg:space-y-0 relative  ">
                <h1 ref={headingRef} className="text-primary text-6xl lg:text-9xl font-main animate-text-float">WEB3
                    DECISION</h1>
                <h3 ref={subheadingRef}
                    className="bg-gradient-to-b from-white via-gray-300 to-gray-500 inline-block text-transparent bg-clip-text text-lg lg:text-4xl italic font-main font-light ">
                    Solutions All Needs on One D-Center
                </h3>
            </div>


            <div ref={dAppsRef} className="flex  space-x-4 lg:space-x-8">
                <button
                    className="bg-secondary hover:bg-primary text-primary hover:text-buttonActiveText p-4 rounded-2xl w-auto border-primary border-2 text-base lg:text-lg font-main relative z-10" onClick={() =>  toast.success('Coming Soon')}>
                    Audit DApp
                </button>
                <button
                    className="bg-secondary hover:bg-red-500 text-red-500 hover:text-buttonActiveText p-4 rounded-2xl w-auto border-red-500 border-2 text-base lg:text-lg font-main relative z-10" onClick={() =>  toast.success('Coming Soon')}>
                    KYC DApp
                </button>
                <button
                    className="bg-secondary hover:bg-green-500 text-green-500 hover:text-buttonActiveText p-4 rounded-2xl w-auto border-green-500 border-2 text-base lg:text-lg font-main relative z-10" onClick={() =>  toast.success('Coming Soon')}>
                    Stakes
                </button>
            </div>
        </div>
    );
}
