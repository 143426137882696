import { Link } from 'react-router-dom'
// import xicon from '../../assets/X-logo-ai.svg'
// import tele from '../../assets/telegram.svg'
// import tiktok from '../../assets/tiktok.svg'
// import mail from '../../assets/email.svg'
// import tube from '../../assets/youtube.svg'
// import fb from '../../assets/facebook.svg'
// import insta from '../../assets/instagram.svg'
// import logo from '../../assets/logo.png'


const SOCIALS = [
  // { logo: '/icons/discord.png', path: 'https://discord.com/invite/tFvSyvy8Wj/' },
  { logo: '/icons/github.png', path: 'https://github.com/web3decision' },
  { logo: '/icons/twitter.png', path: 'https://x.com/web3decision' },
  { logo: '/icons/telegram.png', path: 'https://t.me/web3decision' },
  // { logo: '/icons/telegram.png', path: 'https://t.me/web3decision' },
  // { logo: '/icons/telegram.png', path: 'https://t.me/web3decision' },
];

const Footer = () => {
  return (
    <footer className="mt-auto p-4 bg-darkbackground z-[10] text-[#b4b4b4] text-sm font-normal capitalize w-full">
      <div className="grid grid-cols-1  md:grid-cols-3 gap-6 items-center justify-center ">
        <div className="flex  h-10">
          <img src={'/images/cd_logo.png'} alt="logo" />
        </div>

        <div className="flex max-md:flex-wrap  flex-row items-center justify-end gap-2 mt-4">

        </div>
        <div className="flex max-md:flex-wrap max-md:hidden  flex-row items-center justify-end gap-3 max-md:mt-0 ">
          <img src={'/icons/Google-Ai-Gemini.png'}  alt="logo" className='h-12'/>
          <img src={'/icons/open.svg'}  alt="logo"   className='h-12'/>
          <img src={'/icons/dextools.png'} className='h-12' alt="logo" />
          <img src={'/icons/full-pancakeswap-logo.png'} className='h-12' alt="logo" />
          {/* <img src={'/icons/binance.png'} className='h-12' alt="logo" /> */}
          <img src={'/icons/graph.webp'} className='h-12' alt="logo" />
        </div>

      </div>
      <hr class="my-4 border-gray-200 sm:mx-auto dark:border-gray-700 " />
      <div class="sm:flex sm:items-center sm:justify-between">
        <div class="flex mt-4  gap-2 sm:justify-center sm:mt-0">
          {SOCIALS.map(item =>
            <button className="h-10 w-10 bg-[#0F1013]  border-2 border-primary hover:bg-primary transition-colors duration-300 ease-in-out mb-2  p-2  rounded-full cursor-pointer" onClick={() => window.open(item.path, '_blank')}>
              <img src={item.logo} alt="logo" className='cursor-pointer'/>
            </button>
          )}
        </div>

        <div className="flex flex-col mt-2 items-center md:items-center justify-center md:justify-center font-rajdani">
          <p className='text-sm font-semibold text-white'>
            Web3 Decision, 2024
          </p>
          <p className='text-xs font-semibold text-white'>
            Inc, <a href='#' target='_blank' className='cursor-pointer underline'>Privacy Policy Terms & Conditions.</a>
          </p>

        </div>

        <div className="justify-end  mt-4">
          <div className='flex max-md:hidden  flex-row items-center justify-between  gap-4'>
            <p class="text-md font-semibold text-white border-b-2 border-transparent hover:border-primary transition-all duration-300 ease-in-out">
              HOME
            </p>
            <p class="text-md font-semibold text-white border-b-2 border-transparent hover:border-primary transition-all duration-300 ease-in-out">
              TEAM
            </p>
            <p class="text-md font-semibold text-white border-b-2 border-transparent hover:border-primary transition-all duration-300 ease-in-out">
              $WEB3D
            </p>
            <p class="text-md font-semibold text-white border-b-2 border-transparent hover:border-primary transition-all duration-300 ease-in-out">
              AI MARKETPLACE
            </p>
          </div>
        </div>

      </div>
    </footer>



  )
}

export default Footer
