import {SocialIcon} from "react-social-icons";

export default function Socials() {
    return (
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-8">
            {/* Instagram Card */}
            <div
                className=" backdrop-blur-md p-8 rounded-xl shadow-lg flex flex-col items-center border border-primary/40">
                <SocialIcon network="telegram" style={{height: 48, width: 48}}/>
                <h2 className="text-xl font-semibold mt-4 text-white">Telegram</h2>
                <p className="text-sm text-gray-300 text-center mt-2">
                    Follow us on Telegram for the latest updates and exclusive content.
                </p>
                <a href="https://t.me/web3decision" target="_blank" rel="noopener noreferrer" className="mt-6">
                    <button
                        className="px-6 py-3 bg-white/20 text-white rounded-full transition-transform duration-300 ease-in-out hover:bg-primary hover:scale-105 backdrop-blur-md">
                        Join Community
                    </button>
                </a>
            </div>

            <div
                className=" backdrop-blur-md p-8 rounded-xl shadow-lg flex flex-col items-center border border-primary/40">
                <SocialIcon network="github" style={{height: 48, width: 48}}/>
                <h2 className="text-xl font-semibold mt-4 text-white">Github</h2>
                <p className="text-sm text-gray-300 text-center mt-2">
                    Follow us on Instagram for the latest updates and exclusive content.
                </p>
                <a href="https://github.com/web3decision" target="_blank" rel="noopener noreferrer" className="mt-6">
                    <button
                        className="px-6 py-3 bg-white/20 text-white rounded-full transition-transform duration-300 ease-in-out hover:bg-primary hover:scale-105 backdrop-blur-md">
                        Follow Us
                    </button>
                </a>
            </div>

            {/* Twitter Card */}
            <div
                className=" backdrop-blur-md p-8 rounded-xl shadow-lg flex flex-col items-center border border-primary/40">
                <SocialIcon network="x" style={{height: 48, width: 48}}/>
                <h2 className="text-xl font-semibold mt-4 text-white">X (Twitter)</h2>
                <p className="text-sm text-gray-300 text-center mt-2">
                    Join us on Twitter (X) for real-time news and community engagement.
                </p>
                <a href="https://x.com/web3decision" target="_blank" rel="noopener noreferrer" className="mt-6">
                    <button
                        className="px-6 py-3 bg-white/20 text-white rounded-full transition-transform duration-300 ease-in-out hover:bg-primary hover:scale-105 backdrop-blur-md">
                        Follow Us
                    </button>
                </a>
            </div>


        </div>
    );
}
