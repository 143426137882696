export const workExperience = [
    {
      id: 1,
      title: "Blokzincir güvenliğini derecelendiren yapay zeka destekli uygulama geliştirildi",
      date: "05 - October 2024",
      webName: "Anadolu Ajansi",
      className: "md:col-span-2",
      path: 'https://www.aa.com.tr/tr/insana-dair/blokzincir-guvenligini-derecelendiren-yapay-zeka-destekli-uygulama-gelistirildi/3352157'
    },
    {
      id: 2,
      title: "Blokzincir güvenliğini derecelendiren yapay zeka destekli uygulama geliştirildi",
      date: "05 - October 2024",
      webName: "Eurovizyon",
      className: "md:col-span-2",
      path: 'https://www.eurovizyon.co.uk/blokzincir-guvenligini-derecelendiren-yapay-zeka-destekli-uygulama-gelistirildi'

    },
    {
      id: 3,
      title: "Yapay Zeka Destekli Blokzincir Güvenlik Uygulaması Geliştirildi",
      date: "05 - October 2024",
      webName: "İzmir Gündem Haber",
      className: "md:col-span-2",
      path: 'https://www.izmirgundemhaber.com/teknoloji/yapay-zeka-destekli-blokzincir-guvenlik-uygulamasi-gelistirildi-6029h'

    },
    {
      id: 4,
      title: "Türkiye'nin İlk Yapay Zeka ve Bilim Destekli Blockchain Platformu: Web3D",
      date: "05 - October 2024",
      webName: "EksTv",
      className: "md:col-span-2",
      path: 'https://www.ekstv.com.tr/haber/tuerkiye-nin-ilk-yapay-zeka-ve-bilim-destekli-blockchain-platformu-web3d'

    },
    {
      id: 5,
      title: "Türkiye'nin İlk Yapay Zeka ve Bilim Destekli Blockchain Platformu: Web3D",
      date: "05 - October 2024",
      webName: "Mavi Marmara Gazetesi",
      className: "md:col-span-2",
      path: 'https://www.mavimarmaragazetesi.com/foto/21720643/turkiyenin-ilk-yapay-zeka-ve-bilim-destekli-blockchain-platformu-web3d?4'

    },
    {
      id: 6,
      title: "Türkiye'nin Yapay Zeka ve Bilim Destekli İlk Blockchain Platformu: Web3D",
      date: "05 - October 2024",
      webName: "Elazig Harput Gazetesi",
      className: "md:col-span-2",
      path: 'https://www.elazigharputgazetesi.com/haber/turkiyenin-yapay-zeka-ve-bilim-destekli-ilk-blockchain-platformu-web3d-3007.html'

    },

    {
      id: 7,
      title: "Blokzincir güvenliği için devrim niteliğinde yapay zeka uygulaması hayata geçti",
      date: "05 - October 2024",
      webName: "Egetelgraf",
      className: "md:col-span-2",
      path: 'https://www.egetelgraf.com/blokzincir-guvenligi-icin-devrim-niteliginde-yapay-zeka-uygulamasi-hayata-gecti'

    },
    {
      id: 8,
      title: "Kocaeli'de dolandırıcılığı önleyecek uygulama yazıldı!",
      date: "05 - October 2024",
      webName: "Mavi kocaeli",
      className: "md:col-span-2",
      path: 'https://www.mavikocaeli.com.tr/kocaelide-dolandiriciligi-onleyecek-uygulama-yazildi/193013/'

    },
    {
      id: 9,
      title: "Yapay Zeka Destekli Blokzincir Güvenlik Uygulaması Geliştirildi",
      date: "05 - October 2024",
      webName: "Ayna Haber",
      className: "md:col-span-2",
      path: 'https://www.aynahaber.net/yapay-zeka-destekli-blokzincir-guvenlik-uygulamasi-gelistirildi.h54376'

    },

    {
      id: 9,
      title: "Blokzincir güvenliğini derecelendiren yapay zeka destekli uygulama geliştirildi",
      date: "05 - October 2024",
      webName: "Harber 1",
      className: "md:col-span-2",
      path: 'https://www.haber1.com/haber/web3-decision/'
    },

    {
      id: 9,
      title: "Yapay Zeka Destekli Uygulama ile Blokzincir Güvenliği Artırılıyor",
      date: "05 - October 2024",
      webName: "Haber Özel",
      className: "md:col-span-2",
      path: 'https://www.haberlerozel.com/147199/yapay-zeka-destekli-uygulama-ile-blokzincir-guvenligi-artiriliyor/'
    },

    {
      id: 9,
      title: "Yapay Zeka Destekli Blokzincir Güvenlik Uygulaması Geliştirildi",
      date: "05 - October 2024",
      webName: "Haber 35",
      className: "md:col-span-2",
      path: 'https://www.haberler35.com/yapay-zeka-destekli-blokzincir-guvenlik-uygulamasi-gelistirildi-5414'
    },
  ];


  export const projects = [
    {
      id: 1,
      title: "3D Solar System Planets to Explore",
      des: "Explore the wonders of our solar system with this captivating 3D simulation of the planets using Three.js.",
      img: "/p1.svg",
      iconLists: ["/re.svg", "/tail.svg", "/ts.svg", "/three.svg", "/fm.svg"],
      link: "/ui.earth.com",
    },
    {
      id: 2,
      title: "Yoom - Video Conferencing App",
      des: "Simplify your video conferencing experience with Yoom. Seamlessly connect with colleagues and friends.",
      img: "/p2.svg",
      iconLists: ["/next.svg", "/tail.svg", "/ts.svg", "/stream.svg", "/c.svg"],
      link: "/ui.yoom.com",
    },
    {
      id: 3,
      title: "AI Image SaaS - Canva Application",
      des: "A REAL Software-as-a-Service app with AI features and a payments and credits system using the latest tech stack.",
      img: "/p3.svg",
      iconLists: ["/re.svg", "/tail.svg", "/ts.svg", "/three.svg", "/c.svg"],
      link: "/ui.aiimg.com",
    },
    {
      id: 4,
      title: "Animated Apple Iphone 3D Website",
      des: "Recreated the Apple iPhone 15 Pro website, combining GSAP animations and Three.js 3D effects..",
      img: "/p4.svg",
      iconLists: ["/next.svg", "/tail.svg", "/ts.svg", "/three.svg", "/gsap.svg"],
      link: "/ui.apple.com",
    },
    {
      id: 4,
      title: "Animated Apple Iphone 3D Website",
      des: "Recreated the Apple iPhone 15 Pro website, combining GSAP animations and Three.js 3D effects..",
      img: "/p4.svg",
      iconLists: ["/next.svg", "/tail.svg", "/ts.svg", "/three.svg", "/gsap.svg"],
      link: "/ui.apple.com",
    },
  ];

  export  const colorClasses = {
    marketing: {
        background: "bg-marketingc",
        border: "border-marketingc",
        shadow: "shadow-marketingc",
        text: "text-marketingc",
        hover: "hover:bg-marketingc hover:text-buttonActiveText",
    },
    development: {
        background: "bg-developmentc",
        border: "border-developerc",
        shadow: "shadow-developerc",
        text: "text-developerc",
        hover: "hover:bg-developerc hover:text-buttonActiveText",
    },
    staff: {
        background: "bg-staffc",
        border: "border-staffc",
        shadow: "shadow-staffc",
        text: "text-staffc",
        hover: "hover:bg-staffc hover:text-buttonActiveText",
    }
};